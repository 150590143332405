import React, { useState, useEffect, useRef } from 'react';

import { ScrollArea } from "@/components/ui/scroll-area";

import { AnimatePresence, motion } from "framer-motion";

import socket from 'core/config/socket';

import beep from 'assets/raw/beep.mp3';

export default function CustomeTicketsList(params) {

    const [queue, setQueue] = useState([]);

    const previousQueueRef = useRef([]);
    const beepSound = useRef(new Audio(beep));

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const joinTicket = async (id, status) => {
        params.join(id, status);
    }

    const getStsCss = (status) => {
        switch (status) {
            case 'open':
            case 'iwait':
                return "bg-blue-600";
            case 'pwait':
            case 'live':
                return "bg-yellow-600";
            case 'closed':
                return "bg-green-600";
            case 'hang':
                return "bg-red-600";
            default:
                return "bg-gray-600";
        }
    }

    const getStsText = (status) => {
        switch (status) {
            case 'open':
                return "Open";
            case 'iwait':
                return "Wait for I";
            case 'pwait':
                return "Wait for P";
            case 'live':
                return "Live";
            case 'closed':
                return "Closed";
            case 'hang':
                return "Hang";
            default:
                return "NA";
        }
    }

    const getTypeDept = (type) => {
        switch (type) {
            case 'on-demand':
                return "Face-To-Face";
            case 'on-partner':
                return "Tele-Interpreting";
            case 'helpdesk':
                return "Helpdesk";
            default:
                return "NA";
        }
    }

    useEffect(() => {
        socket.emit('getQueue', {}, (que) => {
            if (que.length === 0) {
                document.title = `ISH | Interpreter - Home`;
            } else {
                document.title = `(${que.length}) ISH | Interpreter - Home`;
            }
            setQueue((prevQueue) => {
                if (JSON.stringify(que) !== JSON.stringify(previousQueueRef.current)) {
                    if (que.length > prevQueue.length) {
                        beepSound.current.play().catch((err) => {
                            console.warn("Sound could not be played:", err);
                        });
                    }
                }
                previousQueueRef.current = que;
                return que;
            });
        });
        socket.on('updateQueue', (que) => {
            if (que.length === 0) {
                document.title = `ISH | Interpreter - Home`;
            } else {
                document.title = `(${que.length}) ISH | Interpreter - Home`;
            }
            setQueue((prevQueue) => {
                if (JSON.stringify(que) !== JSON.stringify(previousQueueRef.current)) {
                    if (que.length > prevQueue.length) {
                        beepSound.current.play().catch((err) => {
                            console.warn("Sound could not be played:", err);
                        });
                    }
                }
                previousQueueRef.current = que;
                return que;
            });
        });
    }, []);

    return (
        <AnimatePresence>
            <motion.div initial={{ width: 0 }} animate={{ width: '28rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                <ScrollArea>
                    <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-lg font-medium pt-4 ml-6">Tickets</motion.h1>
                    <hr className='my-2' />
                    <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="flex flex-col items-start mt-2 w-[20rem] mx-auto">
                        {
                            queue.map((que) => {
                                return <li className="cursor-pointer text-base text-black bg-white p-2 w-full rounded-md border my-2" key={que.position}>
                                    <div className=''>
                                        <div className='flex items-center justify-between'>
                                            <h3 className='text-base font-medium'>{que.name}</h3>
                                            <h3 className='text-xs bg-prime text-white px-3 py-1 rounded-md'>{que.doc.ticketLang}</h3>
                                        </div>
                                        {
                                            que.doc.type === "partner" && <div>
                                                <p className='text-sm my-1 uppercase text-prime font-medium'>{que.doc.partnerDetails.companyName}</p>
                                                <p className='text-xs my-1 uppercase font-medium'>Department - {que.doc.dept.title}</p>
                                            </div>
                                        }
                                        {
                                            que.doc.type !== "partner" && <div>
                                                <p className='text-sm my-1 text-orange-600 font-medium'>{getTypeDept(que.doc.type)}</p>
                                            </div>
                                        }
                                        <p className='text-xs text-gray-900 mt-4 mb-1 font-medium'>Message</p>
                                        <p className='text-xs text-gray-700 my-1'>{que.msg}</p>
                                        <hr className='my-2' />
                                        <div className='flex items-center justify-between'>
                                            <div className={`${getStsCss(que.status)} text-xs rounded-md p-1 w-[100px] text-center text-white`}>{getStsText(que.status)}</div>
                                            {
                                                (que.status === "open" || que.status === "iwait") && <button type="button" className="text-blue-600 hover:text-blue-800 cursor-pointer text-sm float-right" onClick={() => { joinTicket(que.roomid, que.status) }}>
                                                    JOIN NOW
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </li>
                            })
                        }
                        {
                            queue.length === 0 && <li className="cursor-pointer text-base text-black bg-white p-2 w-full rounded-md hover:shadow-md border my-2">
                                <div className=''>
                                    <h3 className='text-base font-medium text-center'>No new tickets</h3>
                                </div>
                            </li>
                        }
                    </motion.ul>
                </ScrollArea>
            </motion.div>
        </AnimatePresence>
    )
}
